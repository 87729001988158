import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import { TypoXS } from '@atoms/Typos';
import { useNeighbor } from '@hooks/useNeighbor';
import type { Neighbor } from '@services/hafh/types/generated';
import routes from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import toast from '@utils/toast';

export const useDisplayFailedPaymentStatus = () => {
  const router = useRouter();
  const { neighbor } = useNeighbor() as { neighbor: Neighbor };

  useEffect(() => {
    if (neighbor?.payment_status === 'failed') {
      toast.notify(
        <TypoXS color="white">
          <Trans
            components={{
              underline: <TypoXS color="white" underline={true} />,
            }}
            i18nKey="toast:error.updateCreditCard"
          />
        </TypoXS>,
        false,
        {
          autoClose: 10_000,
          closeButton: false,
          onClick: () => {
            pushDataLayer({
              event: 'payment_failure_toast_click',
            });
            router.push(routes.creditCardEdit);
          },
          toastId: 'updateCreditCard',
        }
      );
    }
  }, [neighbor, router]);
};
